import CompRebateLayoutThree from '@/components/rebate/comp-rebate-layout-three/index.vue';
import rebateMixin from '@/mixins/nov-new-rebate';
import { getPurchAwardDetail } from '@/service/rebate/novNewRebate';
import RebateTable from '@/components/rebate/rebate-table/index.vue';
import EmptyList from '@/components/common/empty-list/index.vue';

export default {
    name: 'nov-new-rebate-purch-award-detial',
    mixins: [rebateMixin],
    components: {
        CompRebateLayoutThree,
        RebateTable,
        EmptyList,
    },

    data() {
        return {};
    },

    computed: {},

    methods: {
        //  初始化页面
        init() {
            const {
                startTime,
                endTime,
                agentId,
                userInfo: { levelId },
                curActivityId,
                curSystemId,
            } = this;
            getPurchAwardDetail({
                startTime,
                endTime,
                userId: agentId,
                systemId: curSystemId,
                levelId,
                actId: curActivityId,
            }).then(data => {
                this.rebateInfo = data;
            });
        },

        changeSelectedActivity(value) {
            this.curActivity = value;
        },

        changeSelectedSystem(value) {
            this.curSystem = value;
        },
    },
};
